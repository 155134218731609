import React, {useState} from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFieldArray, useForm, SubmitHandler } from "react-hook-form";

import { getExpoFormData } from "./../utils";
import { ExpoFormFields } from "./../types";
import { BackButton } from "./../../ui";

const ExpoForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [error, setError] = useState<string>("")
  const {
    register, control, formState: { errors }, handleSubmit, reset
  } = useForm<ExpoFormFields>({
    defaultValues: {
      characteristic: [{ name: "" }],
      gallery: [{ name: "" }]
    },
    mode: "onTouched"
  });
  const { fields, append, remove } = useFieldArray({
    name: "characteristic",
    control,
    rules: { minLength: 1 }
  });
  const {
    fields: galleryFields, append: galleryAppend, remove: galleryRemove
  } = useFieldArray({
    name: "gallery",
    control,
    rules: { minLength: 1 },
  });

  const onSubmit: SubmitHandler<ExpoFormFields> = async data => {
    setIsLoading(true)
    const url = process.env.REACT_APP_EXPO_URL + "/generate_expo/";
    const formData = getExpoFormData(data);
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })
      if (!response.ok) {
        throw new Error();
      }
      await downloadFile(response)
    } catch (e) {
      setIsError(true)
      setError("Error: Intente nuevamente más tarde")
    } finally {
      setIsLoading(false)
    }
    reset();
  };

  const downloadFile = async (response: Response) => {
    const fileContents = await response.blob()
    const a = document.createElement("a")
    const downloadUrl = URL.createObjectURL(fileContents)
    a.href = downloadUrl
    a.download = "valeria-exposicion.pdf"
    a.click()
    URL.revokeObjectURL(downloadUrl)
  }

  const clearError = () => {
    setIsError(false);
    setError("");
  }

  return (
    <>
      <Box
        width={900}
        my={4}
        mx="auto"
        sx={{
          border: '1px solid lightgrey',
          borderRadius: 2,
          p: 2,
        }}
      >
        <Typography variant="h6" align="center">
          Completa la siguiente información
        </Typography>
        <Box
          component="form"
          my={1}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ p: 1 }}
        >
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="lote_edificio"
                label="Área del lote del edificio:"
                size="small"
                margin="dense"
                type="number"
                {...register("lote_edificio", { required: true })}
                error={errors.lote_edificio?.type === "required"}
                helperText={
                  errors.lote_edificio?.type === "required" ? "Ingresa un área." : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="pisos_edificio"
                label="Número de pisos del edificio:"
                size="small"
                margin="dense"
                type="number"
                {...register("pisos_edificio", { required: true })}
                error={errors.pisos_edificio?.type === "required"}
                helperText={
                  errors.pisos_edificio?.type === "required" ? "Ingresa un número." : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="habitaciones"
                label="Número de habitaciones:"
                size="small"
                margin="dense"
                type="number"
                {...register("habitaciones", { required: true })}
                error={errors.habitaciones?.type === "required"}
                helperText={
                  errors.habitaciones?.type === "required" ? "Ingresa un número." : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="banos"
                label="Número de baños:"
                size="small"
                margin="dense"
                type="number"
                {...register("banos", { required: true })}
                error={errors.banos?.type === "required"}
                helperText={
                  errors.banos?.type === "required" ? "Ingresa un número." : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="terrazas"
                label="Número de terrazas:"
                size="small"
                margin="dense"
                type="number"
                {...register("terrazas", { required: true })}
                error={errors.terrazas?.type === "required"}
                helperText={
                  errors.terrazas?.type === "required" ? "Ingresa un número." : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="parqueaderos"
                label="Número de parqueaderos:"
                size="small"
                margin="dense"
                type="number"
                {...register("parqueaderos", { required: true })}
                error={errors.parqueaderos?.type === "required"}
                helperText={
                  errors.parqueaderos?.type === "required" ? "Ingresa un número." : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="patios"
                label="Número de patios:"
                size="small"
                margin="dense"
                type="number"
                {...register("patios", { required: true })}
                error={errors.patios?.type === "required"}
                helperText={
                  errors.patios?.type === "required" ? "Ingresa un número." : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="estrato"
                label="Estrato:"
                size="small"
                margin="dense"
                type="number"
                {...register("estrato", { required: true })}
                error={errors.estrato?.type === "required"}
                helperText={
                  errors.estrato?.type === "required" ? "Ingresa un estrato." : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="administracion"
                label="Valor administración:"
                size="small"
                margin="dense"
                type="number"
                {...register("administracion", { required: true })}
                error={errors.administracion?.type === "required"}
                helperText={
                  errors.administracion?.type === "required" ? "Ingresa un valor." : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="precio"
                label="Precio:"
                size="small"
                margin="dense"
                type="number"
                {...register("precio", { required: true })}
                error={errors.precio?.type === "required"}
                helperText={
                  errors.precio?.type === "required" ? "Ingresa un precio." : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Características
              </Typography>
            </Grid>
            {fields.map((field, index) => {
              return(
                <React.Fragment key={`frag-${index}`}>
                  <Grid item xs={10}>
                    <TextField
                      key={field.id}
                      fullWidth
                      id="name"
                      label="Característica:"
                      size="small"
                      margin="dense"
                      type="text"
                      {...register(
                        `characteristic.${index}.name` as const, { required: true }
                      )}
                      error={
                        errors.characteristic?.[index]?.name?.type === "required"
                      }
                      helperText={
                        errors.characteristic?.[index]?.name?.type === "required"
                          ? "Ingresa una característica." : null
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {index > 0 && (
                      <Button
                        size="small"
                        sx={{mt: 1.5}}
                        color="error"
                        variant="text"
                        key={`button-${index}`}
                        type="button"
                        onClick={() => remove(index)}
                      >
                        Quitar
                      </Button>
                    )}
                  </Grid>
                </React.Fragment>
              )
            })}
            <Grid item xs={12}>
              <Button
                size="small"
                color="secondary"
                variant="text"
                type="button"
                onClick={() => append({ name: "" })}
              >
                Agregar característica
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Archivos
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="certificado"
                label="Certificado:"
                size="small"
                margin="dense"
                type="file"
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: "application/pdf" }}
                {...register("certificado", { required: true })}
                error={errors.certificado?.type === "required"}
                helperText={
                  errors.certificado?.type === "required" ? "Selecciona un certificado." : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Imágenes
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="logo"
                label="Logo:"
                size="small"
                margin="dense"
                type="file"
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
                {...register("logo", { required: true })}
                error={errors.logo?.type === "required"}
                helperText={
                  errors.logo?.type === "required" ? "Selecciona un logo." : null
                }
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="portada"
                label="Portada:"
                size="small"
                margin="dense"
                type="file"
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
                {...register("portada", { required: true })}
                error={errors.portada?.type === "required"}
                helperText={
                  errors.portada?.type === "required" ? "Selecciona una portada." : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Galería
              </Typography>
            </Grid>
            {galleryFields.map((field, index) => {
              return(
                <React.Fragment key={`sect-frag-${index}`}>
                  <Grid item xs={3}>
                    <TextField
                      key={field.id}
                      fullWidth
                      id="name"
                      label="Sección:"
                      size="small"
                      margin="dense"
                      type="text"
                      {...register(
                        `gallery.${index}.name` as const, { required: true }
                      )}
                      error={
                        errors.gallery?.[index]?.name?.type === "required"
                      }
                      helperText={
                        errors.gallery?.[index]?.name?.type === "required"
                          ? "Ingresa una sección." : null
                      }
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      key={field.id}
                      fullWidth
                      id="images"
                      label="Imágenes:"
                      size="small"
                      margin="dense"
                      type="file"
                      inputProps={{
                        multiple: true,
                        accept: "image/png, image/gif, image/jpeg"
                      }}
                      InputLabelProps={{ shrink: true }}
                      {...register(
                        `gallery.${index}.images` as const, { required: true }
                      )}
                      error={
                        errors.gallery?.[index]?.images?.type === "required"
                      }
                      helperText={
                        errors.gallery?.[index]?.images?.type === "required"
                          ? "Selecciona al menos una imagen." : null
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {index > 0 && (
                      <Button
                        size="small"
                        sx={{mt: 1.5}}
                        color="error"
                        variant="text"
                        key={`button-${index}`}
                        type="button"
                        onClick={() => galleryRemove(index)}
                      >
                        Quitar
                      </Button>
                    )}
                  </Grid>
                </React.Fragment>
              )
            })}
            <Grid item xs={12}>
              <Button
                size="small"
                color="secondary"
                variant="text"
                type="button"
                onClick={() => galleryAppend({ name: "", images: undefined })}
              >
                Agregar sección
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Información adicional
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="folder"
                label="Enlace a carpeta de archivos:"
                size="small"
                margin="dense"
                type="url"
                {...register("folder", { required: true })}
                error={errors.folder?.type === "required"}
                helperText={
                  errors.folder?.type === "required" ? "Ingresa un enlace." : null
                }
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="calendar"
                label="Enlace a calendario:"
                size="small"
                margin="dense"
                type="url"
                {...register("calendar", { required: true })}
                error={errors.calendar?.type === "required"}
                helperText={
                  errors.calendar?.type === "required" ? "Ingresa un enlace." : null
                }
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="disclaimer"
                label="Aviso:"
                size="small"
                margin="dense"
                type="text"
                {...register("disclaimer", { required: true })}
                error={errors.disclaimer?.type === "required"}
                helperText={
                  errors.disclaimer?.type === "required" ? "Ingresa un aviso." : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={5}></Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, mx: "auto" }}
              >
                {isLoading ? "Procesando...": "Generar PDF"}
              </Button>
            </Grid>
          </Grid>
          {isError &&
            <Grid container spacing={1}>
              <Grid item xs={12}>
                  <Alert severity="error" onClose={clearError}>{error}</Alert>
              </Grid>
            </Grid>}
        </Box>
      </Box>
      <Box mt={1} mb={3} mx="auto">
        <BackButton />
      </Box>
    </>
  );
};

export { ExpoForm };
