import React from "react";

import { ExpoForm } from "../features/expo";
import { ScreenTemplate } from "../features/ui";

const Expo: React.FC = () => {
  return (
    <ScreenTemplate>
      <ExpoForm />
    </ScreenTemplate>
  );
};

export { Expo };
