import { ExpoFormFields, Gallery } from "./types";

interface FormDataItems {
  [key: string]: string | Blob;
}

interface FileWithName {
  file: File;
  name: string;
}

const getCategoryImages = (category: Gallery): FileWithName[] => {
  let images: FileWithName[] = []
  const imagesArray = Array.from(category.images || []);
  imagesArray.forEach((image, idx) => images.push({
    file: image, name: `${category.name}_${idx}`
  }));
  return images;
}


const getGalleryFiles = (gallery: Gallery[]): FileWithName[] => {
  let files: FileWithName[] = []
  gallery.forEach(category => files.push(...getCategoryImages(category)));
  return files;
}

export const getExpoFormData = (inputs: ExpoFormFields): FormData => {
  const formData = new FormData();
  const {
    certificado, characteristic, gallery, logo, portada, ...rest
  } = inputs;

  characteristic.forEach(characteristic => formData.append(
    "caracteristicas", characteristic.name
  ))

  formData.append("certificado", certificado[0])
  formData.append("logo", logo[0])
  formData.append("portada", portada[0])

  const galleryFiles = getGalleryFiles(gallery);
  galleryFiles.forEach(file => formData.append(
    "imagenes", file.file, file.name
  ));

  const data = rest as unknown as FormDataItems
  for (const key in data) {
    formData.append(key, data[key]);
  }
  return formData;
}
