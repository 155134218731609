import React from "react";

import { Navigation, ScreenTemplate } from "../features/ui";

const Dashboard: React.FC = () => {
  return (
    <ScreenTemplate>
      <Navigation />
    </ScreenTemplate>
  );
};

export { Dashboard };
