import React from "react";

import Button from '@mui/material/Button';
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { LogoutButton } from ".";

const Navigation: React.FC<any> = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  }

  return (
    <>
      <Box my="auto" mx="auto" display="flex" gap={4}>
        <Button variant="contained" onClick={() => handleClick("/expo")}>
          Exposición
        </Button>
        <Button variant="contained" onClick={() => handleClick("/appraisal")}>
          Avalúo
        </Button>
        <Button variant="contained" onClick={() => handleClick("/profile")}>
          Perfil
        </Button>
      </Box>
      <Box my={2} mx="auto">
        <LogoutButton />
      </Box>
    </>
  );
};

export { Navigation };
