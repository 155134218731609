import React from "react";

import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

const BackButton: React.FC<any> = ({ props }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <Button variant="outlined" onClick={handleBack}>
      Atrás
    </Button>
  );
};

export { BackButton };
