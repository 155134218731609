import React from "react";

import { AppraisalForm } from "../features/appraisal";
import { ScreenTemplate } from "../features/ui";

const Appraisal: React.FC = () => {
  return (
    <ScreenTemplate>
      <AppraisalForm />
    </ScreenTemplate>
  );
};

export { Appraisal };
