import React from "react";

import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

import logo from "../assets/images/logo_with_text.png";
import { ScreenTemplate } from "../features/ui";

const Home: React.FC = () => {
  return <ScreenTemplate>
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={logo} alt="logo" height={500} />
      <Typography variant="subtitle1">
        <Link to="/login">Iniciar sesión</Link>
      </Typography>
    </Box>
  </ScreenTemplate>;
};

export { Home };
