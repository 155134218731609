import React from "react";

import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../features/auth/context/auth.provider";

const LogoutButton: React.FC<any> = ({ props }) => {
  const navigate = useNavigate();
  const { logout } = useAuthContext();

  const handleLogout = () => {
    navigate("/login");
    logout();
  }

  return (
    <Button variant="outlined" color="error" onClick={handleLogout}>
      Salir
    </Button>
  );
};

export { LogoutButton };
