import React, {useState} from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { useController, useFieldArray, useForm, SubmitHandler } from "react-hook-form";

import { ExpoFormFields } from "./../types";
import { BackButton } from "./../../ui";

const Checkboxes = (props: any) => {
  const { options, control, name, legend, helpText } = props;
  const { field } = useController({
    control,
    name
  });
  const [value, setValue] = useState(field.value || []);

  return (
    <FormControl sx={{ my: 2 }} component="fieldset" variant="standard">
      <FormLabel component="legend">{legend}</FormLabel>
      <FormGroup sx={{ m: 1, display: "flex", flexDirection: "row" }}>
        {options.map((option: any, index: number) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={value.includes(option)}
                onChange={(e) => {
                  const valueCopy = [...value];
                  // update checkbox value
                  valueCopy[index] = e.target.checked ? e.target.value : null;
                  // send data to react hook form
                  field.onChange(valueCopy);
                  // update local state
                  setValue(valueCopy);
                }}
                value={option}
                name={option} />
            }
            label={option}
          />
        ))}
      </FormGroup>
      <FormHelperText>{helpText}</FormHelperText>
    </FormControl>
  );
};

const AppraisalForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [error, setError] = useState<string>("")
  const {
    register, control, formState: { errors }, handleSubmit, reset
  } = useForm<ExpoFormFields>({
    defaultValues: {},
    mode: "onTouched"
  });
  const { fields: observationFields, append, remove } = useFieldArray({
    name: "observacion",
    control,
    rules: { minLength: 0 }
  });
  const { fields: decretoFields, append: decretoAppend, remove: decretoRemove } = useFieldArray({
    name: "decreto",
    control,
    rules: { minLength: 0 }
  });
  const { fields: viaFields, append: viaAppend, remove: viaRemove } = useFieldArray({
    name: "via_acceso",
    control,
    rules: { minLength: 0 }
  });

  const onSubmit: SubmitHandler<ExpoFormFields> = async data => {
    setIsLoading(true);
    console.log(data);
    // const url = process.env.REACT_APP_EXPO_URL + "/generate_expo/";
    // const formData = getExpoFormData(data);
    // try {
    //   const response = await fetch(url, {
    //     method: "POST",
    //     body: formData,
    //   })
    //   if (!response.ok) {
    //     throw new Error();
    //   }
    //   await downloadFile(response)
    // } catch (e) {
    //   setIsError(true)
    //   setError("Error: Intente nuevamente más tarde")
    // } finally {
    //   setIsLoading(false)
    // }
    reset();
  };

  const downloadFile = async (response: Response) => {
    const fileContents = await response.blob()
    const a = document.createElement("a")
    const downloadUrl = URL.createObjectURL(fileContents)
    a.href = downloadUrl
    a.download = "valeria-exposicion.pdf"
    a.click()
    URL.revokeObjectURL(downloadUrl)
  }

  const clearError = () => {
    setIsError(false);
    setError("");
  }

  return (
    <>
      <Box
        width={900}
        my={4}
        mx="auto"
        sx={{
          border: '1px solid lightgrey',
          borderRadius: 2,
          p: 2,
        }}
      >
        <Typography variant="h6" align="center">
          Completa la siguiente información
        </Typography>
        <Box
          component="form"
          my={1}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ p: 1 }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Avalúo
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="numero"
                label="Número del avalúo:"
                size="small"
                margin="dense"
                type="text"
                {...register("numero", { required: true })}
                error={errors.numero?.type === "required"}
                helperText={
                  errors.numero?.type === "required" ? "Ingresa un número." : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Ingresa el número de consecutivo de tu avalúo. Ej:  001/2024">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="solicitante"
                label="Solicitante:"
                size="small"
                margin="dense"
                type="text"
                {...register("solicitante")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Opcional. Ej: Juan Perez con cédula de ciudadanía número 123456789 de Santa Marta">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                select
                id="tipo"
                label="Tipo de avalúo:"
                size="small"
                margin="dense"
                {...register("tipo", { required: true })}
                error={errors.tipo?.type === "required"}
                helperText={
                  errors.tipo?.type === "required" ? "Escoge un tipo de avalúo." : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title="helper text pending">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="Catastral">
                  Catastral
                </MenuItem>
                <MenuItem value="Comercial">
                  Comercial
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                select
                id="marco_juridico"
                label="Marco jurídico:"
                size="small"
                margin="dense"
                {...register("marco_juridico", { required: true })}
                error={errors.marco_juridico?.type === "required"}
                helperText={
                  errors.marco_juridico?.type === "required" ? "Escoge un marco jurídico." : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title="helper text pending">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="informe">
                  Informe
                </MenuItem>
                <MenuItem value="comercial">
                  Comercial
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="estrato"
                label="Estrato:"
                size="small"
                margin="dense"
                type="number"
                {...register("estrato", { required: true })}
                error={errors.estrato?.type === "required"}
                helperText={
                  errors.estrato?.type === "required" ? "Ingresa un estrato." : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Observaciones jurídicas
              </Typography>
            </Grid>
            {observationFields.map((field, index) => {
              return(
                <React.Fragment key={`frag-${index}`}>
                  <Grid item xs={10}>
                    <TextField
                      key={field.id}
                      fullWidth
                      id="name"
                      label="Observación:"
                      size="small"
                      margin="dense"
                      type="text"
                      {...register(
                        `observacion.${index}.value` as const, { required: true }
                      )}
                      error={
                        errors.observacion?.[index]?.value?.type === "required"
                      }
                      helperText={
                        errors.observacion?.[index]?.value?.type === "required"
                          ? "Ingresa una observación." : null
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      size="small"
                      sx={{mt: 1.5}}
                      color="error"
                      variant="text"
                      key={`button-${index}`}
                      type="button"
                      onClick={() => remove(index)}
                    >
                      Quitar
                    </Button>
                  </Grid>
                </React.Fragment>
              )
            })}
            <Grid item xs={12}>
              <Button
                size="small"
                color="secondary"
                variant="text"
                type="button"
                onClick={() => append({ value: "" })}
              >
                Agregar observación
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Decretos
              </Typography>
            </Grid>
            {decretoFields.map((field, index) => {
              return(
                <React.Fragment key={`frag-${index}`}>
                  <Grid item xs={10}>
                    <TextField
                      key={field.id}
                      fullWidth
                      id="name"
                      label="Decreto:"
                      size="small"
                      margin="dense"
                      type="text"
                      {...register(
                        `decreto.${index}.value` as const, { required: true }
                      )}
                      error={
                        errors.decreto?.[index]?.value?.type === "required"
                      }
                      helperText={
                        errors.decreto?.[index]?.value?.type === "required"
                          ? "Ingresa un decreto." : null
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      size="small"
                      sx={{mt: 1.5}}
                      color="error"
                      variant="text"
                      key={`button-${index}`}
                      type="button"
                      onClick={() => decretoRemove(index)}
                    >
                      Quitar
                    </Button>
                  </Grid>
                </React.Fragment>
              )
            })}
            <Grid item xs={12}>
              <Button
                size="small"
                color="secondary"
                variant="text"
                type="button"
                onClick={() => decretoAppend({ value: "" })}
              >
                Agregar decreto
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Alrededores
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="delimitacion_norte"
                label="Delimitación al norte:"
                size="small"
                margin="dense"
                type="text"
                {...register("delimitacion_norte", { required: true })}
                error={errors.delimitacion_norte?.type === "required"}
                helperText={
                  errors.delimitacion_norte?.type === "required" ? "Ingresa esta información." : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe con qué limita el inmueble por el norte">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="delimitacion_sur"
                label="Delimitación al sur:"
                size="small"
                margin="dense"
                type="text"
                {...register("delimitacion_sur", { required: true })}
                error={errors.delimitacion_sur?.type === "required"}
                helperText={
                  errors.delimitacion_sur?.type === "required" ? "Ingresa esta información." : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe con qué limita el inmueble por el sur">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="delimitacion_oriente"
                label="Delimitación al oriente:"
                size="small"
                margin="dense"
                type="text"
                {...register("delimitacion_oriente", { required: true })}
                error={errors.delimitacion_oriente?.type === "required"}
                helperText={
                  errors.delimitacion_oriente?.type === "required" ? "Ingresa esta información." : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe con qué limita el inmueble por el oriente">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="delimitacion_occidente"
                label="Delimitación al occidente:"
                size="small"
                margin="dense"
                type="text"
                {...register("delimitacion_occidente", { required: true })}
                error={errors.delimitacion_occidente?.type === "required"}
                helperText={
                  errors.delimitacion_occidente?.type === "required" ? "Ingresa esta información." : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe con qué limita el inmueble por el occidente">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                select
                id="public_transport"
                label="Transporte público:"
                size="small"
                margin="dense"
                type="text"
                {...register("public_transport", { required: true })}
                error={errors.public_transport?.type === "required"}
                helperText={
                  errors.public_transport?.type === "required" ? "Selecciona una opción." : null
                }
              >
                <MenuItem value="constante">
                  Constante
                </MenuItem>
                <MenuItem value="esporadico">
                  Esporádico
                </MenuItem>
                <MenuItem value="inexistente">
                  Inexistente
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="esparcimiento"
                label="Esparcimiento y recreación:"
                size="small"
                margin="dense"
                type="text"
                {...register("esparcimiento")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Opcional. Menciona si hay centros comerciales, parques, espacios deportivos, etc.">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Vías de acceso
              </Typography>
            </Grid>
            {viaFields.map((field, index) => {
              return(
                <React.Fragment key={`frag-${index}`}>
                  <Grid item xs={10}>
                    <TextField
                      key={field.id}
                      fullWidth
                      id="value"
                      label="Vía de acceso:"
                      size="small"
                      margin="dense"
                      type="text"
                      {...register(
                        `via_acceso.${index}.value` as const, { required: true }
                      )}
                      error={
                        errors.via_acceso?.[index]?.value?.type === "required"
                      }
                      helperText={
                        errors.via_acceso?.[index]?.value?.type === "required"
                          ? "Ingresa esta información." : null
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      size="small"
                      sx={{mt: 1.5}}
                      color="error"
                      variant="text"
                      key={`button-${index}`}
                      type="button"
                      onClick={() => viaRemove(index)}
                    >
                      Quitar
                    </Button>
                  </Grid>
                </React.Fragment>
              )
            })}
            <Grid item xs={12}>
              <Button
                size="small"
                color="secondary"
                variant="text"
                type="button"
                onClick={() => viaAppend({ value: "" })}
              >
                Agregar vía de acceso
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mt={2}>
                Edificio
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                select
                id="infraestructura"
                label="Infraestructura:"
                size="small"
                margin="dense"
                type="text"
                {...register("infraestructura", { required: true })}
                error={errors.infraestructura?.type === "required"}
                helperText={
                  errors.infraestructura?.type === "required" ? "Selecciona una opción." : null
                }
              >
                <MenuItem value="totalidad">
                  Totalidad
                </MenuItem>
                <MenuItem value="parcial">
                  Parcial
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="frentes_edificio"
                label="Número de frentes edificio:"
                size="small"
                margin="dense"
                type="number"
                {...register("frentes_edificio")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id="numero_pisos"
                label="Número de pisos:"
                size="small"
                margin="dense"
                type="number"
                {...register("numero_pisos")}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="cimentacion"
                label="Cimentación:"
                size="small"
                margin="dense"
                type="text"
                {...register("cimentacion")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe la cimentación del edificio">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="estructura"
                label="Estructura:"
                size="small"
                margin="dense"
                type="text"
                {...register("estructura")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe la estructura del edificio">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="iluminacion"
                label="Iluminación:"
                size="small"
                margin="dense"
                type="text"
                {...register("iluminacion")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe la iluminación del edificio">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="ventilacion"
                label="Ventilación:"
                size="small"
                margin="dense"
                type="text"
                {...register("ventilacion")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe la ventilación del edificio">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="entrada"
                label="Entrada:"
                size="small"
                margin="dense"
                type="text"
                {...register("entrada")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe la entrada del edificio">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="vias_vehiculares"
                label="Vías vehiculares:"
                size="small"
                margin="dense"
                type="text"
                {...register("vias_vehiculares")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Describe las vías vehiculares del edificio">
                        <InfoOutlinedIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkboxes
                options={["Agua", "Energía", "Gas", "Internet"]}
                control={control}
                legend="Servicios públicos"
                name="servicios_publicos"
                helpText="Selecciona los que aplican"
              />
            </Grid>
            <Grid item xs={12}>
              <Checkboxes
                options={[
                  "Edificación moderna, con buenos acabados, diseño arquitectónico y funcionalidad para la éроса de la construcción",
                  "El Edificio cuenta con parqueadero para propietarios y visitantes",
                  "No se observaron problemas de estabilidad",
                  "No se observaron elementos o problemáticas ambientales que afecten de manera negativa o positiva al inmueble",
                  "No se observaron afectaciones viales, servidumbres o cesiones",
                  "No se observaron problemas de seguridad, degradación social, escasez de protección individual y/o colectiva, y proliferación de prácticas delictivas"
                ]}
                control={control}
                legend="Descripción"
                name="descripcion"
                helpText="Selecciona las que apliquen"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={5}></Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, mx: "auto" }}
              >
                {isLoading ? "Procesando...": "Generar PDF"}
              </Button>
            </Grid>
          </Grid>
          {isError &&
            <Grid container spacing={1}>
              <Grid item xs={12}>
                  <Alert severity="error" onClose={clearError}>{error}</Alert>
              </Grid>
            </Grid>}
        </Box>
      </Box>
      <Box mt={1} mb={3} mx="auto">
        <BackButton />
      </Box>
    </>
  );
};

export { AppraisalForm };
